<template lang="pug">
  main#main.statically-terms
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-info-circle')
      section.l-section
        .l-section-h
          component(:is='locale')
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'

export default {
  name: 'statically-terms',
  components: {
    WcBreadcrumb,
  },
  computed: {
    locale() {
      /* Reactivity computed by call actual locale. */
      this.$i18n.locale
      return () =>
        import(`@views/container/statically/terms/${this.$wc.shared.locale}`).catch(() =>
          import(`@views/container/statically/terms/${this.$wc.conf.base.default_locale}`)
        )
    },
  },
}
</script>
